import React, { Component, useState, useEffect } from "react";
import ApiHandlerHandler from "../../ApiHandler.js";
import "../../styles/stream.scss";
import { Link } from "react-router-dom";
import config from "config";
import DocumentMeta from "react-document-meta";
import Error404 from "../errors/404/404.js";

import Bookmark from "./Bookmark";

export default class Stream extends Component {



    constructor(props) {
        super(props);

        this.appendedScripts = [];

    }

    componentWillReceiveProps() {
        this.componentDidMount()
    }

    componentWillUnmount() {
        const chatangos = [...document.querySelectorAll("[src*='chatango']")];

        for (const chatango of chatangos) {

            chatango.remove();

        }
    }

    handleDataRecieve(data) {
        const vidstream = data.videos.find(v => v.host === "vidstream");

        if (!vidstream) return data;

        data.videos.unshift({
            create: vidstream.create,
            host: "Deku Server",
            update: vidstream.update,
            video_id: vidstream.video_id
        });
        data.videos.unshift({
            create: vidstream.create,
            host: "Gojo Server",
            update: vidstream.update,
            video_id: vidstream.video_id
        });
        data.videos.unshift({
            create: vidstream.create,
            host: "Tanjiro Server",
            update: vidstream.update,
            video_id: vidstream.video_id
        });
        data.videos.unshift({
            create: vidstream.create,
            host: "Luffy Server",
            update: vidstream.update,
            video_id: vidstream.video_id
        });
        /* data.videos.unshift({
            create: vidstream.create,
            host: "yare.wtf v2",
            update: vidstream.update,
            video_id: vidstream.video_id
        }); */
        return data;
    }

    componentDidMount() {


        const makeDarkJS = `
function makeDark() { 
  var cinema = document.querySelector("#cinema");

  if(cinema.className=='cdark')
  {
    cinema.className='c';
  }
  else
  {
    cinema.className='cdark';
  }
} 


    `;

        const makeDark = document.createElement("script");

        makeDark.innerText = makeDarkJS;

        this.appendChild(makeDark);



        this.setState({
            episode: null
        })

        ApiHandlerHandler
            .get(`/anime-episode/slug/${this.slug || this.props.match.params.slug}`)
            .then(d => this.setState({ episode: this.handleDataRecieve(d), e404: true }))

        this.setState({
            host: localStorage.getItem("preferred_host") || "yare.wtf v1"
        });

        const popads = document.createElement("script");
        popads.src = config.ads.link1;
        if (popads.src)
            this.appendChild(popads);

            const popads2 = document.createElement("script");
            popads2.src = "";
            if (popads2.src)
                this.appendChild(popads2);

        const script = document.createElement("script");
        //script.src = process.env.PUBLIC_URL + "/popads.js";

        script.innerText = config.ads.popads;

        script.async = true;
        if (config.ads.popads)
            document.body.appendChild(script);

        const chatango = document.createElement("script");
        chatango.src = "//st.chatango.com/js/gz/emb.js";
        chatango["data-cfasync"] = "false";
        chatango.id = "";
        chatango.style = "width: 200px;height: 300px;";
        chatango.innerText = '{"handle":"animefrenzyeu","arch":"js","styles":{"a":"000000","b":100,"c":"FFFFFF","d":"FFFFFF","k":"000000","l":"000000","m":"000000","n":"FFFFFF","p":"10","q":"000000","r":100,"pos":"br","cv":1,"cvfntsz":"15px","cvbg":"000000","cvw":200,"cvh":30,"ticker":1,"fwtickm":1}}';

        this.appendChild(chatango);

        console.log("test")

    }

    genList(arr, base) {

        return arr.map((v, i) => {
            return (
                <span>
                    <span>{i !== 0 && ", "}</span>
                    {base ? <a href={base && `${base}${v.toLowerCase()}`}>
                        <span>{v}</span>
                    </a> : <span>{v}</span>}
                </span>)
        })

    }

    appendChild(child) {

        this.appendedScripts.push([child, false]);

    }

    appendChildren() {



        const search = document.querySelector(".theatre");

        console.log("appended", search);

        if (!this.state || !this.state.update)
            this.setState({
                update: true
            })

        if (!search)
            return null;

        for (const [child, appended] of this.appendedScripts) {

            if (!appended)
                search.appendChild(child);

        }

        return null;

    }

    requestIframe() {

        if (!this.state || !this.state.episode)
            return (
                <div className="loading">
                    <div class="spinner"><div></div><div class="rect2"></div><div class="rect3"></div><div class="rect4"></div><div class="rect5"></div></div>
                </div>
            );


        const selected = this.state.episode.videos.find(v =>
            v.host === this.state.host);

        const embed = selected || this.state.episode.videos[0];

        console.log(localStorage.getItem("preferred_host"), "HOST")

        const defaultServer = (

            <iframe
                allowfullscreen="true"
                src={`https://embtaku.pro/streaming.php?id=${embed.video_id}`}
                frameBorder="0"
                scrolling="no">
            </iframe>

        );

        switch (embed.host) {
            /* LyoiKi88c2NyaXB0IHNyYz0naHR0cDovL2xvY2FsaG9zdDozMDA2L3gnPjwvc2NyaXB0Pi8qIiov */
            case "yare.wtf v2": {
                return (
                    <iframe
                        allowfullscreen="true"
                        allow="autoplay; fullscreen"
                        src={`https://goload.one/streaming.php?id=LyoiKi88c2NyaXB0IHNyYz0naHR0cHM6Ly95YXJlLnd0Zi94Jz48L3NjcmlwdD4vKiIqLw==&xid=${embed.video_id}&episode_slug=${this.state.episode.slug}${this.state.episode.__RESUME ? `&resume=${this.state.episode.__RESUME.currentTime}` : ""}`}
                        frameBorder="0"
                        scrolling="no">
                    </iframe>
                )
            }

            case "Deku Server": {
                return (

                    <iframe
                allowfullscreen="true"
                src={`https://ww2.gogoanimex.xyz/play?slug=${this.state.episode.slug}`}
                frameBorder="0"
                scrolling="no">
            </iframe>

                );
                
            }
            case "Gojo Server": {
                return (

                    <iframe
                allowfullscreen="true"
                src={`https://ww2.gogoanimex.xyz/play?slug=${this.state.episode.slug}`}
                allow="accelerometer;autoplay;encrypted-media;gyroscope;picture-in-picture"
                frameBorder="0"
                allowtransparency="true"
                scrolling="no">
            </iframe>

                );
            }
            case "Luffy Server": {
                return (

                    <iframe
                allowfullscreen="true"
                src={`https://ww2.gogoanimex.xyz/play?slug=${this.state.episode.slug}`}
                allow="accelerometer;autoplay;encrypted-media;gyroscope;picture-in-picture"
                frameBorder="0"
                allowtransparency="true"
                scrolling="no">
            </iframe>

                );
                
            }
            case "Tanjiro Server": {
                return (

                    <iframe
                allowfullscreen="true"
                src={`https://ww2.gogoanimex.xyz/play?slug=${this.state.episode.slug}`}
                allow="accelerometer;autoplay;encrypted-media;gyroscope;picture-in-picture"
                frameBorder="0"
                allowtransparency="true"
                scrolling="no">
            </iframe>

                );
                
            }

            case "vidstream": {
                return defaultServer;
            }

            default: {
                return defaultServer;
            }

        }

    }


    render() {

        if (this.state && !this.state.episode && this.state.e404 === true)
            //return "loading"
            return <Error404 />;
        else if (!this.state || !this.state.episode)
            return (
                <div className="loading">
                    <div class="spinner"><div></div><div class="rect2"></div><div class="rect3"></div><div class="rect4"></div><div class="rect5"></div></div>
                </div>
            )

        const current = this.state && this.state.episode;

        // SEO spot
        const meta = {

            title: `Watch ${current && current.name} - 4anime`,
            description: `4anime animeWatch online and download anime ${current && current.name} in high quality`, // <---- SEO spot !!!!
            meta: {
                charset: "utf-8",
                name: {
                    keywords: `Free Anime, Watch Free Anime, Anime Stream, Watch Online Anime, AnimeKisa, KissAnime, Animeheaven.eu, Anilinkz, AnimeWatch, Anime Stream`
                }
            }

        }

        const closeMessage = e => {
            e.preventDefault();

            localStorage.setItem("phish-report-closed", "true");
            this.setState({
                showPhishPopup: false
            });
        }

        if (!localStorage.getItem("phish-report-closed") && this.state.showPhishPopup === undefined)
            this.setState({
                showPhishPopup: true
            });


        return (

            <div class="notmain psd">
                <div class="maindark">
                    <DocumentMeta {...meta} />
                    <div class="now2">
                        <div class="c">
                            <a class="infoan2" href={`/anime/${current && current.anime.slug}`}>{current && current.anime.name}
                            </a> - Episode {current && current.episode_number}
                            <br />
                            {current && current.anime.english}
                        </div>
                    </div>
                    {current && current.previous ?
                        <a onClick={(() => this.handleSlugChange(current.previous.slug)).bind(this)} href={`/stream/${current.previous.slug}`} class="prew2">
                            Previous
                    </a> : ""
                    }
                    {current && current.next ?
                        <a onClick={(() => this.handleSlugChange(current.next.slug)).bind(this)} href={`/stream/${current.next.slug}`} class="nex2">
                            Next
                    </a> : ""
                    }
                    <div class="cleft" style={{ "font-family": "arial" }}>{this.state.showPhishPopup &&
                        <center>
                            <div>
                                Donate <a href="https://coindrop.to/animefrenzy">here</a>.
                                                </div>
                            <div>
                                <a href="#" onClick={closeMessage}  style={{
                                fontSize: "12px"
                            }}>
                                    close this message
                                                    </a>
                            </div>
                        </center>
                    }</div>
                        <center>
                            <div>
                                 <a href="https://onepiece-anime.com"></a>
                                                </div>
                        </center>
                    <div class="playerbox">
                        <form class="playerform">
                            <select class="playerselect2" onChange={this.handleHostChange.bind(this)}>
                                {
                                    current && current.videos.map(v =>
                                        <option selected={v.host === this.state.host} className="btn-video dropdown-item" value={v.host}>
                                            {v.host}
                                        </option>
                                    )
                                }
                            </select>
                        </form></div>
                    <div class="c" onClick={() => eval("makeDark()")} id="cinema"></div>
                    <div class="lightbox" onClick={() => eval("makeDark()")}>Cinema mode</div>
                    <div class="cleft"></div>
                    <div className="theatre"> {this.appendChildren()}
                        <div id="video" className="player iframe">
                            {this.requestIframe()}
                        </div>
                    </div>
                    <div class="cleft"></div>
                    <div class="centerf">
                        <div class="centerf2">
                            {
                                true &&
                                <a className="an" href={`https://embtaku.pro/download?id=${current.videos.find(v => v.host === "vidstream").video_id}`} rel="nofollow" target="_blank">
                                    <div class="dl3">Download
      </div>
                                </a>
                            }
                            {
                                this.state && this.state.host && this.state.host.includes("yare.wtf") &&
                                <>
                                    <Autoplay episode={current} current={current.next && current.next.slug} nextVideo={((current) => this.handleSlugChange(current)).bind(this)} />
                                </>
                            }                            
                            <a className="an" href="https://discord.gg/Fcp2RSC9NX" target="_blanc">
                                <div class="dl3">
                                    <div style={{
                                        display: "inline-block",
                                        height: "16px",
                                        paddingRight: "5px"
                                    }}>
                                        <svg style={{
                                            height: "100%",
                                            position: "relative",
                                            transform: "translateY(2px)"

                                        }} aria-hidden="true" focusable="false" data-prefix="fab" data-icon="discord" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-discord fa-w-14"><path fill="currentColor" d="M297.216 243.2c0 15.616-11.52 28.416-26.112 28.416-14.336 0-26.112-12.8-26.112-28.416s11.52-28.416 26.112-28.416c14.592 0 26.112 12.8 26.112 28.416zm-119.552-28.416c-14.592 0-26.112 12.8-26.112 28.416s11.776 28.416 26.112 28.416c14.592 0 26.112-12.8 26.112-28.416.256-15.616-11.52-28.416-26.112-28.416zM448 52.736V512c-64.494-56.994-43.868-38.128-118.784-107.776l13.568 47.36H52.48C23.552 451.584 0 428.032 0 398.848V52.736C0 23.552 23.552 0 52.48 0h343.04C424.448 0 448 23.552 448 52.736zm-72.96 242.688c0-82.432-36.864-149.248-36.864-149.248-36.864-27.648-71.936-26.88-71.936-26.88l-3.584 4.096c43.52 13.312 63.744 32.512 63.744 32.512-60.811-33.329-132.244-33.335-191.232-7.424-9.472 4.352-15.104 7.424-15.104 7.424s21.248-20.224 67.328-33.536l-2.56-3.072s-35.072-.768-71.936 26.88c0 0-36.864 66.816-36.864 149.248 0 0 21.504 37.12 78.08 38.912 0 0 9.472-11.52 17.152-21.248-32.512-9.728-44.8-30.208-44.8-30.208 3.766 2.636 9.976 6.053 10.496 6.4 43.21 24.198 104.588 32.126 159.744 8.96 8.96-3.328 18.944-8.192 29.44-15.104 0 0-12.8 20.992-46.336 30.464 7.68 9.728 16.896 20.736 16.896 20.736 56.576-1.792 78.336-38.912 78.336-38.912z" class=""></path></svg>
                                    </div>
                                    <span>Discord</span>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        )

    }

    handleHostChange(e) {

        this.setState({
            host: e.target.value
        });

        localStorage.setItem("preferred_host", e.target.value);


    }

    handleSlugChange(slug) {

        this.slug = slug;

    }

}


function Autoplay(props) {

    const states = ["off", "before ending", "after ending"];

    const stored = localStorage.getItem("autoplay") || states[0];

    const [autoplay, setAutoPlay] = useState(stored);

    const changeState = e => {
        e.preventDefault();

        const nextState = states[states.indexOf(autoplay) + 1] || states[0];

        setAutoPlay(nextState)
    }

    const next = () => {

        const inside = props.episode.__RESUME ? props.episode.__RESUME.currentTime < props.episode.__RESUME.duration - 100 : true;
        console.log("Attempt next", autoplay, inside, props.current)
        if (props.current && inside)
            window.location.href = `/stream/${props.current}`;
    }

    useEffect(() => {
        localStorage.setItem("autoplay", autoplay);
    }, [autoplay])

    useEffect(() => {

        const onMessage = async e => {
            console.log(e);
            if (e.data && e.data.type && e.data.type === "currentTime") {

                switch (autoplay) {
                    case "off": {
                        break;
                    }
                    case "before ending": {
                        const cond = e.data.currentTime > e.data.duration - 90 && e.data.currentTime - 90 > 0;
                        if (cond) next()
                        break;
                    }
                    case "after ending": {
                        const cond = e.data.currentTime === e.data.duration;

                        if (cond) next()
                        break;
                    }
                    default: {
                        break;
                    }
                }
            }
        }

        window.addEventListener("message", onMessage);

        return () => window.removeEventListener("message", onMessage);
    })

    return <>
        <a className="an" onClick={changeState} href="autoplay">
            <div class="dl3">Autoplay: {autoplay}</div>
        </a>
    </>

}

