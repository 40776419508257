import React, { Component } from "react";
import { Link } from "react-router-dom";
import DocumentMeta from "react-document-meta";
import EpisodeComponent from "../EpisodeComponent.js";
import PopularAnimeComponent from "../PopularAnimeComponent.js";
import AnimeComponent from "../AnimeComponent.js";

import ApiHandler from "../../ApiHandler.js";

import "../../styles/animeList.scss";

import Sidebar from "../sidebar/Sidebar";

import Pagination from "../../lib/Pagination.js";

import config from "config";

class AnimeList extends Component {

	constructor(props) {
		super(props);

		this.state = {}

		this.setState({ latest: [] });
		this.setState({ latestPage: 0, OngoingAnimePage: 0, trendingAnimePage: 0 });
	}

	componentDidMount() {

		function sortObjectByKeys(o) {
			return Object.keys(o).sort().reduce((r, k) => (r[k] = o[k], r), {});
		}

		ApiHandler

            .get(`/latest`)
            .then(d => {
              this.setState({ latest: d });
            });

		ApiHandler

			.get(`/anime/slugs`)
			.then(d => {

				const letters = {};
				const sorted = d
					.map(v => {
						if (!v.name.length)
							v.name = v.slug.split("-").filter(v => v && v.length).map(v => v.toUpperCase()).join(" ");
						return v;
					})
					.sort((a, b) => {
						return a.name.localeCompare(b.name);
					})
				for (const anime of sorted) {

					const match = anime.name.trim().match(/[a-zA-Z0-9]/);

					const first = match ? match[0] : "#"


					const letter = Number.isInteger(+first) ? "#" : first.toUpperCase();

					if (!letters[letter])
						letters[letter] = [];

					letters[letter].push(anime);

				}


				this.setState({
					anime: sortObjectByKeys(letters)
				});

			})


		this.randomItems = {

			randomTV: "/anime/random/TV"

		};

		["randomTV"]
			.forEach(this.refreshRandom.bind(this));

	}


	buildNavigation() {

		return (

<>
					{
						"#ABCDEFGHIJKLMNOPQRSTUVWXXYZ"
							.split("")
							.map(v => {
								return (
									<a className="gridvan" href={"#anchor-" + v}>
										<div className="gridpgmain">
										  <div class="gridpg">
										  	{v}
										  </div>
										</div>
									</a>
								);
							})
						
					}
					<a className="gridvan" href="/browse"><div class="gridv2main"><div class="gridv2">Grid</div></div></a>
</>

		)

	}

	buildList() {

		const exists = !!(this.state && this.state.anime);

		if (!exists)
			return (
				<div className="loading">
				<div class="spinner"><div></div><div class="rect2"></div><div class="rect3"></div><div class="rect4"></div><div class="rect5"></div></div>
				</div>
			);

		const { anime } = this.state;

		return (

			<>

				{
					Object.entries(anime)
						.map(([key, items]) => {
							return (
							<>
								<div class="lisb" id={"anchor-" + key}>
							       {key}
							    </div>
								
										{
											items.map(v => {
												return (
												
														<Link to={`/anime/${v.slug}`}>
														   <div class="lis">
															{v.name}
														   </div>
														</Link>
													
												)
											})
										}
								</>

							)
						})
				}

			</>

		)

	}

	render() {

		const meta = {

				title: `Anime List - Watch Most Popular Animes Online - ${config.website.name}`,
			    description: `Watch anime here planet of anime english subbed and dubbed anime sites for you 720p and 1080p.`,
			    meta: {
			        charset: "utf-8",
			        name: {
			            keywords: ``
			        }
			    }
			
			}

			const { latest } = this.state;

		return (

			<div className="notmain asd">

			<DocumentMeta {...meta} />
				
				<div className="maindark">

				<div class="lisbox">

					<div className="gridpgboxmain">
					<div className="gridpgbox">

						{this.buildNavigation()}
</div>
					</div>


						{this.buildList()}


					</div>

					<div class="sidemain">

					<div className="noas">
         				
						<Sidebar />
					</div>
					</div>

				</div>
			
			</div>
		)

	}


	refreshRandom(key) {

		if (!this.state)

		this.setState({
			[key]: null
		});

		ApiHandler
			.get(this.randomItems[key])
			.then(d => this.setState({ [key]: d }));

	}

	buildRandom(key) {

		if (!this.state || !this.state[key]) return (
		<div className="loading">
		   Loading
		</div>
		);

		const anime = this.state[key];


		return (
		<>
		<div class="c">
    <a class="an" href={`/anime/${anime.slug}`}>
      <img class="coveri lazy" src={`${config.api.imageBase}${anime.image}`} alt={anime.name} />
    </a>
  </div>
  <div class="rnd">
  {anime.name}
  </div>
  </>
		)

	} 

	/*<EpisodeComponent episode={v} />*/

	handlePageSwap(key, indexa) {

		setTimeout(() => {
			this.setState({
				loading: {
					[key]: {
						active: false
					}
				}
			})
		}, 0)
		this.setState({
			[key]: indexa,
			loading: {
				[key]: {
					active: true,
					style: this[key].getBoundingClientRect()
				}
			}
		})

	}

	buildOngoing(items, current, key, component, title) {
		
		const pagination = new Pagination(items)
			.perPage(1000)
			.padding(1, 1)
			.gutter(1, 1)
			.get(current);

		return (
			<>
					{pagination.nav.currentPage.items.map((v, i) => 
						React.createElement(component, {
							payload: v,
							key: i + "key",
							index: v
						})
					)}
				</>
		)

	}

	buildPopular(items, current, key, component, title) {
		
		const pagination = new Pagination(items)
			.perPage(10)
			.padding(1, 1)
			.gutter(1, 1)
			.get(current);

		return (
			<>
					{pagination.nav.currentPage.items.map((v, i) => 
						React.createElement(component, {
							payload: v,
							key: i + "key",
							index: v
						})
					)}
				</>
		)

	}

}


export default AnimeList