import React, { Component } from "react";
import { Link } from "react-router-dom";

import config from "config";

class AnimeComponent extends Component {

	constructor(props) {
		super(props);

	}

	componentDidMount() {

		this.setState({ mounted: true });

	}

	render(v, i) {

		const style = {

			"animationDelay": (+this.props.index) * 0.04 + "s" 

		}
		
		return (
      <a class="an" href={`/anime/${this.props.payload.slug}`}><div class="lis">{this.props.payload.name}</div></a>

		);
	}

}


export default AnimeComponent;