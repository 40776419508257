import React, { useEffect, useState } from "react";
import ApiHandler from "../../ApiHandler.js";


function Register(props) {

    const [message, setMessage] = useState("");

    const submit = async e => {
        e.preventDefault();

        setMessage("Creating account...")

        const form = e.target;

        const data = Object.fromEntries([...new FormData(form)]);

        const res = await ApiHandler.get("/anime/register", {
            method: "POST",
            body: JSON.stringify(data)
        });

        eval("grecaptcha.reset();");

        setMessage(res.message);

    }

    useEffect(() => {
        setMessage("")
    }, [setMessage]);

    return <>
        <div style={{
            display: props.shown ? "block" : "none"
        }} class="register">
            <form onSubmit={submit}>
                <div class="form-header">
                    <h1>Register Account</h1>
                </div>
                <div class="form-inputs">
                    <div className="input-cont">
                        <input required minLength={2} maxLength={32} className="searchinput" type="text" name="username" placeholder="Username" />
                    </div>
                    <div className="input-cont">
                        <input required minLength={6} maxLength={99} className="searchinput" type="password" name="password" placeholder="Password" />
                    </div>
                    <div className="input-cont">
                        <div class="g-recaptcha" data-theme="dark" data-sitekey="6LfiPuAUAAAAACLD3Irq_J2h5RN9ZawN1hiDuR7J"></div>
                    </div>
                    <div className="input-cont">
                        <a onClick={ props.showLogin } href="#login">Already have an account?</a>
                    </div>
                    <div className="input-cont">
                        <button className="submit-button">
                            Create account
                        </button>
                    </div>
                    <div className="input-cont">
                        {message}
                    </div>
                </div>
            </form>
        </div>
    </>;

}

export default Register;