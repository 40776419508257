import React, { Component } from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { useRecoilValue } from "recoil";
import { userData } from "../../state/account";

export default function EpisodeList(props) {

  const data = useRecoilValue(userData);

  const { anime } = props;
  const dubbed = anime.language !== "subbed";

  

  return <>
    {
      anime.episodes.length ?
        anime.episodes
          .filter((v, i, a) => a.findIndex(t => (t.episode_number === v.episode_number)) === i)
          .sort((x, y) => y.episode_number - x.episode_number)
          .map(v => {


            const episode = props.progress && props.progress.find(k => k.slug === v.slug)

            return (
              <Link class="infovan" to={`/stream/${v.slug}`}>
                <div title={`${v.name}`} class="infoepmain" style={{
                  "--progress": episode ? episode.currentTime / episode.duration < .1 ? .1 : episode.currentTime / episode.duration > .92 ? 1 : episode.currentTime / episode.duration : 0
                }}>
                  <div class="infoep">
                    <div class="infoept">
                      <div class="infoept1">
                        <div class="centerv">Episode</div>
                      </div>
                      <div class="infoept2">
                        <div class="centerv">{v.episode_number}</div>
                      </div>
                      <div class="infoept3">
                        <div class="date-format-diff centerv"><Moment fromNow>
                          {v.create}
                        </Moment></div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            )

          })

        : <h2>Oh no! Looks like there aren't any episodes for this anime. Check back later.</h2>
    }
  </>


}