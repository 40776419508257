import React from "react";

function Button(props) {


    return <>
        <a className="an" href={props.link}>
            <div class="dl3">{props.children}</div>
        </a>
    </>;

}

export default Button;