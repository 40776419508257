import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DocumentMeta from "react-document-meta";
import Search from "../../lib/Search.js";
import config from "config";
import { isAuthenticated } from "../../state/account";

import {
	useRecoilValue,
	useRecoilState
} from "recoil";

import { showRegister, defaultRegisterShown } from "../../state/models";

const Header = props => {


	const searchBar = React.createRef();

	const [ menuActive, setMenuActive ] = React.useState(false);
	const [ searchActive, setSearchActive ] = React.useState(false);

	function toggleMenu(e) {
		e.preventDefault();

		setMenuActive(!menuActive);

	}

	function toggleSearch(e) {
		e.preventDefault();

		setSearchActive(!searchActive);


	}


	// SEO spot
	const meta = {

		title: `${config.website.name}: Watch Anime Online High Quality Anime 720p and 1080p .`,
		description: `${config.website.name} Watch the anime you love, subbed or dubbed in Full HD and without ads - only at Weebcity.`,
		meta: {
			charset: "utf-8",
			name: {
				keywords: `Watch the anime you love, subbed or dubbed in Full HD and without ads - only at Weebcity.`
			}
		}

	}

	const baseMenu = config.header.baseItems;

	const [ menu, setMenu ] = useState([
		...baseMenu
	]);

	const authenticated = useRecoilValue(isAuthenticated);

	const [, setShowRegister ] = useRecoilState(showRegister);
	const [, setDefaultRegisterShown ] = useRecoilState(defaultRegisterShown);

	

	useEffect(() => {

		const proposed = [
			...baseMenu,
			...(
				authenticated ? [
					{
						name: "",
						path: "/profile",
						action: () => console.log("hi")
					}
				] : [{
					name: "",
					path: "#login",
					action: () => {

						setDefaultRegisterShown(false);
						setShowRegister(true);

					}
				},
				{
					name: "",
					path: "#register",
					action: () => {

						setDefaultRegisterShown(true);
						setShowRegister(true);

					}
				}]
			)
		];

		if (JSON.stringify(proposed) !== JSON.stringify(menu))
			setMenu(proposed);

	}, [ setMenu, authenticated, baseMenu, setShowRegister, menu, setDefaultRegisterShown ])

	return (
		<>
			<DocumentMeta {...meta}>
				<link href="https://fonts.googleapis.com/css?family=Roboto:400,700|Roboto%20Condensed:400,700" rel="stylesheet" />
			</DocumentMeta>
			<div class="header">
				<div class="headermain" itemscope="itemscope" itemtype="http://schema.org/SiteNavigationElement" role="navigation">
					<a href="/">
						<div class="logo">
						</div>
					</a>
					<div class="searchbox">
						<form action="/browse">
							<Search showSearch={ searchActive } />
						</form>
					</div>
					{
						menu.reverse().map(menuItem => <>
							<a class="menue" href={menuItem.path}>
								<div onClick={menuItem.action || false} class="menua">{menuItem.name}</div>
							</a>
						</>)
					}

					<div class={`burger ${menuActive ? "active" : ""}`} id="burgeri" onClick={toggleMenu}>
					</div>
				</div>
			</div>
			<div class={`burgermenu ${menuActive ? "active" : ""}`} itemscope="itemscope" itemtype="http://schema.org/SiteNavigationElement" role="navigation">
				<div class="line">
				</div>
				{
					menu.reverse().map(menuItem => <>
						<a class="menue2" href={menuItem.path}>
							<div onClick={menuItem.action || false} class="menua">{menuItem.name}</div>
						</a>
					</>)
				}


			</div>

		</>

	);

	function submitSearch(e) {
		e.preventDefault();

		const term = searchBar.current.value;

		this.props.history.push(encodeURI(`/browse?search=${term}`));

	}

}

export default Header;