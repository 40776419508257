import React, { useEffect, useState } from "react";

import Login from "./Login";
import Register from "./Register";

import "../../styles/registrationModel.scss";
import { showRegister, defaultRegisterShown } from "../../state/models";

import { useRecoilValue, useRecoilState } from "recoil";
import Popup from "../popup/Popup";

function BaseRegistration(props) {

    const showRegisterDefault = useRecoilValue(defaultRegisterShown);

    const [registerActive, setRegisterActive] = useState(showRegisterDefault)

    useEffect(() => {

        setRegisterActive(showRegisterDefault);

    }, [showRegisterDefault, setRegisterActive]);

    const [shown, setShown] = useRecoilState(showRegister);

    return <>
        <Popup shown={shown} onClose={() => setShown(false)}>
            <div className="base-register-inner">
                <div className="register-nav">
                    <div className="nav-item-cont">
                        <a onClick={e => e.preventDefault() || setRegisterActive(true)} href="#register" className={`${registerActive ? "nav-active" : ""}`}>
                            <div className="register-nav-item">
                                Register
                            </div>
                        </a>
                    </div>
                    <div className="nav-item-cont">
                        <a onClick={e => e.preventDefault() || setRegisterActive(false)} href="#login" className={`${!registerActive ? "nav-active" : ""}`}>
                            <div className="register-nav-item">
                                Login
                            </div>
                        </a>
                    </div>
                </div>
                <div className="register-body">
                    <Login shown={!registerActive} showRegister={e => e.preventDefault() || setRegisterActive(true)} />
                    <Register shown={registerActive} showLogin={e => e.preventDefault() || setRegisterActive(false)} />
                </div>
            </div>
        </Popup>
    </>;

}

export default BaseRegistration;