import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import ApiHandler from "../../ApiHandler.js";


export default class Random extends Component {

	componentDidMount() {

		ApiHandler
			.get("/anime/random")
			.then(d => {
				this.setState({
					loc: `/anime/${d.slug}`
				});
			});

	}

	render() {

		return (

			<div>
				<h1>Finding random anime... hold tight!</h1>
				{this.state && this.state.loc && <Redirect to={this.state.loc} />}
			</div>

		)

	}


}