import React, { Component } from "react";
import { Link } from "react-router-dom";

import Moment from "react-moment";


import config from "config";

class EpisodeComponent extends Component {

  constructor(props) {
    super(props);

  }

  serializeDistinctArray(arr) {

      return arr
          .filter(v => v)
          .map(v => {
              return {
                  slug: v.trim().toLowerCase().replace(/ /gm, "-"),
                  name: v.trim()
              }
          })

  }

  genList(arr, base) {

        return arr.map((v, i) => {
            return (
            <span>
                <span>{i !== 0 && ", "}</span>
                {base ? <Link to={base && `${base}${this.serializeDistinctArray([v])[0].slug}`}>
                    <span>{v}</span>
                </Link> : <span>{v}</span>}
            </span>)
        })

    }

  componentDidMount() {

    this.setState({ mounted: true });

  }

  render(v, i) {

    const anime = this.props.payload.anime;
    const dubbed = anime.language !== "subbed";

    const style = { 

      "animationDelay": (+this.props.index) * 0.04 + "s" 

    }

    return (

<div class="content_episode" itemscope="itemscope" itemtype="http://schema.org/CreativeWork">
  <div class="conm">
    <a class="cona" href={`/anime/${this.props.payload.anime.slug}`}>{this.props.payload.anime.name}
    </a>
    <div class="con2">{this.props.payload.anime.genres && this.props.payload.anime.genres.length ? (this.genList(anime.genres, "/browse/?sort=a-z&genres=")) : "N/A"}
          </div>
  </div>
  <div class="iep">
    <div class="ieppic">
      <a class="an" href={`/stream/${this.props.payload.slug}`}>
        <img class="coveri lazy" alt={this.props.payload.anime.name}
                src={`${config.api.imageBase}${this.props.payload.anime.image}`} itemprop="image" />
      </a>
    </div>
    <div class="iepcon">
      <div class="iepsbox">
        <Link to={`/stream/${this.props.payload.slug}`}>
          <div class="ieps">
            <div class={`ieps2 ${ dubbed ? "dub" : ""}`}>
              <div class="iepst1">
                <div class="centerv">Episode
                </div>
              </div>
              <div class="iepst2">
                <div class="centerv">{this.props.payload.episode_number}
                </div>
              </div>
              <div class="iepst3">
                <div class="date-format-diff centerv"> <Moment fromNow>
                {this.props.payload.create}
            </Moment>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
      <div class="con">
        <div class="condd">
          <Link class="cona" to={`/anime/${this.props.payload.anime.slug}`}>{this.props.payload.anime.name}
          </Link>
          <h3 class="entry-title hidden" itemprop="headline">{this.props.payload.anime.name}
          </h3>
          <div class="con2">{this.props.payload.anime.genres && this.props.payload.anime.genres.length ? (this.genList(anime.genres, "/browse/?sort=a-z&genres=")) : "N/A"}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    );
  }

}


export default EpisodeComponent;