import React from "react";
import { useRecoilState } from "recoil";
import { showDonationPopup } from "../../state/models";

import Popup from "../../lib/popup/Popup";
import config from "config";

import "../../styles/donate.scss";

function DonateButton(props) {

    const [shown, setShown] = useRecoilState(showDonationPopup);

    const onClick = e => {
        e.preventDefault();

        setShown(true);
    }

    return <>
        <a className="an" href="#donate" onClick={onClick}>
            <div class="dl3">DONATE</div>
        </a>
        <Popup shown={shown} onClose={() => setShown(false)}>
            <div className="donation">
                <div className="donation-inner">
                    <div className="donation-padding">
                        <h1>Support {config.website.name}</h1>
                    </div>
                    <div className="hr-fake"></div>
                    <div className="donation-padding">
                        <p>Send your donation to this bitcoin address:</p>
                        <div className="donation-code">
                            {config.website.donation.qr.code}
                        </div>
                        <i><p>or scan the QR code below (bitcoin)</p></i>
                        <img className="qr-code" alt="bitcoin qr code" src={config.website.donation.qr.image} />
                        <a href="https://coindrop.to/animefrenzy"> Coindrop</a>
                    </div>
                </div>
            </div>
        </Popup>
    </>;

}

export default DonateButton;