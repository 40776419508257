import React, { Component } from "react";
import "../../styles/page.scss";
import DocumentMeta from "react-document-meta";


export default class AnimeList extends Component {

    render() {

    	const meta = {

				title: `DMCA`,
			    description: `DMCA`,
			    meta: {
			        charset: "utf-8",
			        name: {
			            keywords: `DMCA`
			        }
			    }
			
			}

        return (

            <div className="page">
            <DocumentMeta {...meta} />
            DubbedAnime does not store any files on our server, we only linked to the media which is hosted on 3rd party services.
            </div>

        )

    }

}