import { atom } from "recoil";


export const isAuthenticated = atom({
    key: "isAuthenticated",
    default: null
})

export const userData = atom({
    key: "userData",
    default: null
});