import React, { useEffect, useState } from "react";
import config from "config";
import { useRecoilValue, useRecoilState } from "recoil";

import { isAuthenticated, userData } from "../../state/account";

import ApiHandler from "../../ApiHandler.js";
import Episode from "./Episode";
export default function ContinueWatching(props) {

    const data = useRecoilValue(userData);
    const token = window.localStorage.getItem("token");
    const [list, setList] = useState(data?.data.watchedUnique.slice(props.limit) || []);
    const [showButton, setShowButton] = useState(true);

    useEffect(() => {
        setList(data?.data.watchedUnique.slice(props.limit) || [])
    }, [data]);

    if (!data) return <></>;

    const exists = [];

    const loadMore = async () => {
        const res = await ApiHandler.get("/anime/user/watched/more?offset=" + list.length, {
            method: "POST",
            body: JSON.stringify({
                token
            })
        });

        if (res?.payload?.data?.watchedUnique?.length)
            setList([...list, ...res.payload.data.watchedUnique]);
        else
            setShowButton(false);

    }

    return <>
        {props.title || <h2>
            Continue watching
        </h2>}

        <div class="iepbox">
            {list.length ? <>
                {
                    list.map(episode => <Episode episode={episode} />)
                }

            </> : <>
                    <h2>Nothing to display! Go watch some anime!</h2>
                </>}
            {list.length && <div
                style={{
                    display: showButton ? "flex" : "none"
                }}
                class="load-more-cont">
                <button onClick={loadMore} class="load-more">
                    Load more
                    </button>
            </div>}
        </div>
    </>
}
