import React, { Component } from "react";
import ApiHandler from "../../ApiHandler.js";
import EpisodeComponent from "../EpisodeComponent.js";
import PopularAnimeComponent from "../PopularAnimeComponent.js";
import AnimeComponent from "../AnimeComponent.js";

import Sidebar from "../sidebar/Sidebar";

import { Link } from "react-router-dom";


import { 
	Select,
	Checkbox,
	FormControlLabel,
	InputLabel,
	FormControl,
	Input,
	MenuItem,
	TextField
} from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import BanimeComponent from "../BanimeComponent.js";
import DocumentMeta from "react-document-meta";
import "../../styles/index.scss";
import "../../styles/browse.scss";

import Pagination from "../../lib/Pagination.js";

import config from "config";

const darkTheme = createMuiTheme({
  palette: {
  	primary: {
  		main: "#e81e30"
  	},
    type: "dark",
  },
});

class Browse extends Component {

	constructor(props) {
		super(props);

		
		
	}

	componentDidMount() {



		const filters = [
			["/types/all", "Filters"],
		].map(([path, key]) =>
			ApiHandler
				.get(path)
				.then(data => {
					Object.entries(data).forEach(([key, value]) => {
						this.setState({

							[key]: value

						})
					});		

				})
		);
		
		const url = new URL("https://goog.com" + this.props.history.location.search);

		const params = [...url.searchParams];

		const _filters = {};

		for (const [key, value] of params) {

			if (["genres", "status", "year", "type"].includes(key)) {
				_filters[key] = decodeURI(value).split(",")
			} else {
				_filters[key] = decodeURI(value)
			}

		}


		this.setState({
			filter: _filters
		});

		console.log(this.state)

this.randomItems = {

			randomTV: "/anime/random/TV"

		};

		["randomTV"]
			.forEach(this.refreshRandom.bind(this));

	}

	handleAnimeRequest() {



		if (this.advancedRequest)
			this.advancedRequest.abort();

		this.setState({
			anime: null
		})

		const query = this.buildQueryString();
		this.advancedRequest = ApiHandler.ApiJson(`/advanced${query}`);

		this.advancedRequest
			.fetch()
			.then(anime => {
				this.setState({
					anime
				});
			})
			.catch(e => {});

		
		
		const current = this.props.history.location.pathname + this.props.history.location.search;

		const proposed = `/browse/${query}`;


		if (current != proposed)
			this.props.history.replace(proposed);
		
	}

	buildQueryString() {

		if (!this.state.filter)
			return "";

		const url = new URL("https://google.com")

		for (const [key, value] of Object.entries(this.state.filter)) {

			const serializedValue = (v => {

				switch (typeof v) {
					case "object": {
						const toArray = v instanceof Array ? v : Object.values(v);

						return toArray.join(",");
					}
					case "string": {
						return v;
					}
					case "number": {
						if (key === "page") {
							console.log(v);
							if (v === 0)
								return ""
							else
								return +v + 1 + "";
						}
					}
				}

				return "" + v
			})(value);

			if (serializedValue.length)
				url.searchParams.append(key.toLowerCase(), serializedValue);

			

		}

		const stringed = url.toString();

		return stringed.includes("?") ? "?" + stringed.split("?").pop() : "";

	}

	handleTextInput(key, e) {

		if (key !== "page")
			this.handleTextInput("page", {
				target: {
					value: 0
				}
			})

		const previousFilter = this.state.filter || {}
		const filter = Object.assign(previousFilter, {
			[key]: e.target.value
		});

		this.setState({
			filter
		});

	}

	render() {

		const meta = {

				title: `Browse Popular Anime List - Watch Most Popular Animes Online - ${config.website.name}`,
			    description: `Watch anime here planet of anime english subbed and dubbed anime sites for you 720p and 1080p.`,
			    meta: {
			        charset: "utf-8",
			        name: {
			            keywords: ``
			        }
			    }
			
			}

		if (this.state) {

			if (JSON.stringify(this.state.filter || []) !== this.previousFilter) {
				this.handleAnimeRequest();
			}
			
			this.previousFilter = JSON.stringify(this.state.filter || []);

			const { latest } = this.state;

			return (
				<ThemeProvider theme={darkTheme}>
					<div className="notmain psd">
					<div className="maindark">
					<div className="iepbox">
					<div id="episodes-subbed">
					<DocumentMeta {...meta} />
					<div className="browse-navigation home-release grid">

									{this.buildSorter("sort", this.state.sort)}
									{this.buildFilter("type", this.state.type)}
									{this.buildFilter("genres", this.state.genres)}
									{this.buildFilter("status", this.state.status)}
									{this.buildFilter("year", this.state.year)}

								</div>
					<div className="popular psd">
									{this.buildAnimeListing()}
							</div>
					<div className="pg2box">
									{this.buildNavigation()}
							</div>
					</div>
					</div>
				<div class="sidemain">

				<div className="noas">
					<Sidebar />
</div>

				</div>
					</div>
					</div>
				</ThemeProvider>
			)
		}

		return <div>Loading...</div>

	}

	buildNavigation() {

		const { anime } = this.state;

		if (!anime)
			return null;

		const buildNavIndex = (v, name) =>
			v ? <div
				index={`${v.index}`}
				onClick={e => this.handlePageSwap("page", v.index)}
				className={
					`nav-item
						${v.show || v.dotdotdot ? "" : "hide"}
						${v.current ? "current" : ""}
						${v.dotdotdot ? "disabled" : ""}
					`
				}>
				<div className="pg">{typeof name === "string" ? name : (v.dotdotdot ? `···` : v.name)}</div>
			</div> : <span></span>

		const validAnimeState = anime && anime.hasOwnProperty("nav");

		return (

			<div className="pagination-nav-popu">

				<div className="global-flex-1"></div>

				<span className="hide-on-mobile">
					{validAnimeState && buildNavIndex(anime.nav.first, "<<")}
				</span>
				{validAnimeState && buildNavIndex(anime.nav.previous, "<")}
				<div className="hide-on-mobile global-inline-flex">
					{validAnimeState && anime.nav.nav.map(buildNavIndex)}
				</div>

				{validAnimeState && buildNavIndex(anime.nav.next, ">")}

				<span className="hide-on-mobile">
					{validAnimeState && buildNavIndex(anime.nav.last, ">>")}
				</span>

				<div className="global-flex-1"></div>
			</div>

		)


	}

	refreshRandom(key) {

		if (!this.state)

		this.setState({
			[key]: null
		});

		ApiHandler
			.get(this.randomItems[key])
			.then(d => this.setState({ [key]: d }));

	}

	buildRandom(key) {

		if (!this.state || !this.state[key]) return (
		<div className="loading">
		   Loading
		</div>
		);

		const anime = this.state[key];


		return (
		<>
		<div class="c">
    <Link class="an" to={`/anime/${anime.slug}`}>
      <img class="coveri lazy" src={`${config.api.imageBase}${anime.image}`} alt={anime.name} />
    </Link>
  </div>
  <div class="rnd">
  {anime.name}
  </div>
  </>
		)

	} 

	/*<EpisodeComponent episode={v} />*/

	handlePageSwap(key, indexa) {

		setTimeout(() => {
			this.setState({
				loading: {
					[key]: {
						active: false
					}
				}
			})
		}, 0)
		this.setState({
			[key]: indexa,
			loading: {
				[key]: {
					active: true,
					style: this[key].getBoundingClientRect()
				}
			}
		})

	}

	buildOngoing(items, current, key, component, title) {
		
		const pagination = new Pagination(items)
			.perPage(1000)
			.padding(1, 1)
			.gutter(1, 1)
			.get(current);

		return (
			<>
					{pagination.nav.currentPage.items.map((v, i) => 
						React.createElement(component, {
							payload: v,
							key: i + "key",
							index: v
						})
					)}
				</>
		)

	}

	buildPopular(items, current, key, component, title) {
		
		const pagination = new Pagination(items)
			.perPage(10)
			.padding(1, 1)
			.gutter(1, 1)
			.get(current);

		return (
			<>
					{pagination.nav.currentPage.items.map((v, i) => 
						React.createElement(component, {
							payload: v,
							key: i + "key",
							index: v
						})
					)}
				</>
		)

	}

	buildAnimeListing() {

		const { anime } = this.state;

		if (!anime || !(anime && anime.nav && anime.nav.currentPage)) {
			if (anime && anime instanceof Array && !anime.length)
				return (
					<h1>No results</h1>
				);

			return (
				<div className="loading a">
				<div class="spinner"><div></div><div class="rect2"></div><div class="rect3"></div><div class="rect4"></div><div class="rect5"></div></div>
				</div>
			);
		}

		
		const component = BanimeComponent;


		return (

					<div class="uk-light">
						{anime.nav.currentPage.items.map((v, i) => 
							React.createElement(component, {
								payload: v,
								key: i + "key",
								index: i
							})
						)}
					</div>

		)

	}

	buildSorter(key, items) {

		const MenuProps = {
			getContentAnchorEl: null
		};

		return (

			<FormControl variant="filled">
				<InputLabel>{key.substr(0, 1).toUpperCase() + key.substr(1)}</InputLabel>
					<Select
						MenuProps={MenuProps}
						variant="filled"
						size="medium"
						defaultValue="relevance"
						onChange={e => this.handleTextInput(key, e)}
						value={this.state.filter && this.state.filter[key] ? this.state.filter[key] : []}
						input={<Input />}
						renderValue={(selected) =>
							`${selected}`
						}
					>
					{items && items.map(item => (
						<MenuItem
							value={item.slug}
							key={item.slug}
						>
							{item.name}
						</MenuItem>
					))}
				</Select>
			</FormControl>

		)

	}


	buildFilter(key, items) {

		const MenuProps = {
			getContentAnchorEl: null
		};

		return (

			<FormControl variant="filled">
				<InputLabel>{key.substr(0, 1).toUpperCase() + key.substr(1)}</InputLabel>
					<Select
						MenuProps={MenuProps}
						variant="filled"
						size="medium"
						multiple
						defaultValue={key}
						onChange={e => this.handleTextInput(key, e)}
						value={this.state.filter && this.state.filter[key] ? this.state.filter[key] : []}
						input={<Input />}
						renderValue={(selected) =>
							`(${selected.length}) ${selected.join(", ")}`
						}
					>
					{items && items.map(item => (
						<MenuItem
							value={item.slug}
							key={item.slug}
						>
							<FormControlLabel
								key={item.slug}
								label={item.name}
								control={
									<Checkbox checked={
										this.state.filter &&
										typeof this.state.filter === "object" &&
										this.state.filter[key] ?
										this.state.filter[key].some(v => v === item.slug) : false} key={item.slug} />
								}

							/>
						</MenuItem>
					))}
				</Select>
			</FormControl>

		)

	}



	handlePageSwap(key, value) {

		this.handleTextInput(key, {
			target: {
				value
			}
		})

	}

}

export default Browse;