import React, { Component } from "react";
import { Link } from "react-router-dom";

import config from "config";

class BanimeComponent extends Component {

	constructor(props) {
		super(props);

	}

	componentDidMount() {

		this.setState({ mounted: true });

	}

	render(v, i) {

		const style = {

			"animationDelay": (+this.props.index) * 0.04 + "s" 

		}
		
		return (
<Link class="an" to={`/anime/${this.props.payload.slug}`}>
  <div class="similarcmain">
    <div class="similarc">
      <div class="similarpic">
        <img class="coveri" src={`${config.api.imageBase}${this.props.payload.image}`} itemprop="image" />
      </div>
      <div class="similard">
        <div class="similardd">{this.props.payload.name}
        </div>
      </div>
    </div>
  </div>
</Link>


		);
	}

}


export default BanimeComponent;