import React, { Component } from "react";
import { Link } from "react-router-dom";
import DocumentMeta from "react-document-meta";
import config from "config";

class Footer extends Component {

	render() {

		return (
			<footer class="footer">
				<div class="footer-cont maindark">
					<div class="footer-list">
						<div class="footer-inner">
							{
								config.footer.section1.map(v => <>
									<div class="footer-item"><a class="footera" target={v.target || ""} href={v.path}>{v.name}</a></div>
								</>)
							}
						</div>
						<div class="footer-inner">
							{
								config.footer.section2.map(v => <>
									<div class="footer-item"><a class="footera" target={v.target || ""} href={v.path}>{v.name}</a></div>
								</>)
							}
						</div>
					</div>
					<div class="footert2">Copyright © {config.website.name} {config.website.owner.name}</div>
				</div>
			</footer>


		)

	}


}

export default Footer;