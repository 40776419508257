import React, { Component } from "react";
import { Link } from "react-router-dom";

import ApiHandler from "../ApiHandler.js";

import "../styles/search.scss";

import config from "config";

export default class Search extends Component {

	constructor(props) {
		super(props);

		this.input = React.createRef();
	}

	componentDidMount() {

		this.setState({
			shown: false
		})

	}

	render() {

		return (

			<div id="searchx" className={`auto-complete-cont searchx ${this.state && this.props.showSearch ? "active" : ""}`}>

				<div className="auto-complete form-inline searchform"
					tabIndex="-1"
					onFocus={() => this.setState({ shown: true })}
					onBlur={() => this.setState({ shown: false })}
				>
					<input className="searchinput"
						ref={this.input}
						placeholder="Search"
						name="search"
						autoComplete="off"
						onInput={this.handleTextInput.bind(this)} />

					<input class="searchsubmit" type="submit" value="" />
					<div
						tabIndex="-1"
						className={`suggestions-cont ${this.state && this.state.shown ? "shown" : "hidden"}`}
					>

						{this.buildSuggestions()}

						{this.state && this.state.entries && this.state.entries instanceof Array && this.state.entries.length === 5 &&
							<button className="show-more" type="submit">Show more</button>
						}

					</div>

				</div>


			</div>

		)

	}

	buildSuggestions() {

		if (!this.state) return null;

		const { entries } = this.state;

		if (!entries)
			return (
				<div className="loadingsa">
					Loading...
				</div>
			);

		if (entries && entries === -1)
			return null;

		if (entries && entries.length === 0)
			return (
				<div className="no-result">No results :(</div>
			)

		return entries.map(v =>
			<a class="name" className="" href={`/anime/${v.slug}`}>
				<div className="item fx-none">
					<img class="thumb" alt={v.name} src={`${config.api.imageBase}${v.image}`} />
					<div className="info">
						{v.name}
						<div className="meta">
							<span style={{
								display: (v.english + "").toLowerCase().trim() === (v.name + "").toLowerCase().trim() ? "none" : "block"
							}}><span class="year"></span><span class="yearzi">{v.english}</span></span>
						</div>
						<div className="genre">
						</div>
					</div>
				</div>
			</a>
		)

	}

	async requestAutoComplete(query) {

		return await ApiHandler
			.silent(`/anime/auto-complete/${encodeURI(query)}`);

	}


	async handleTextInput(e) {

		const query = e.currentTarget.value;

		this.setState({
			entries: null
		});

		if (!query.length)
			return this.setState({
				entries: -1
			})

		const entries = await this.requestAutoComplete(query);

		this.setState({
			entries
		});


	}

}


