import config from "config";
import NProgress from "nprogress";

class ApiHandler {


	static async get(path, options = {}, showProgress = true) {

		showProgress && NProgress.start();

		const url = new URL(config.api.proxy.base + path)

		url.searchParams.append("token", config.api.token);

		const token = window.localStorage.getItem("token");

		if (token) {
			if (!options.headers)
				options.headers = {}

			options.headers.authorization = token;
		}

		const init = await fetch(url, options);
			
		showProgress && NProgress.inc(0.8);

		const finalize = await init.json();

		showProgress && NProgress.done();

		return finalize.data;

	}

	static async silent(path) {

		//NProgress.start();

		const url = new URL(config.api.proxy.base + path)

		url.searchParams.append("token", config.api.token);

		const init = await fetch(url);
			
		//NProgress.inc(0.8);

		const finalize = await init.json();

		//NProgress.done();

		return finalize.data;

	}


	static ApiJson(path) {

		return new ApiJson(path)

	}


}

class ApiJson extends ApiHandler {

	constructor(path) {
		super(path);

		this.path = path;

		this.controller = new AbortController();
		this.signal = this.controller.signal;

		return this;

	}

	fetch() {


		return new Promise((resolve, reject) => {

			NProgress.start();

			const url = new URL(config.api.proxy.base + this.path);
			url.searchParams.append("token", config.api.token);

			const { signal } = this;
			fetch(url, { signal })
				.then(d => NProgress.done() && d.json())
				.then(({data}) => resolve(data))
				.catch(reject);
				

		});


	}

	abort() {

		NProgress.done();

		this.controller.abort();

	}

}


export default ApiHandler