import React from "react";
import Moment from "react-moment";
import config from "config";
import { useRecoilValue, useRecoilState } from "recoil";

import { isAuthenticated, userData } from "../../state/account";

function Episode(props) {

    const { episode } = props;

    return <>
        <div>
            {!episode.episode_number && <div class="conm">
                <a class="cona" href={`/anime/${episode.slug}`}>{episode.episode_name.split("Episode").shift().trim()}</a>
                <div class="con2">
                    <span><span>
                    </span>
                        <a href="/browse/?sort=a-z&amp;genres=contemporary-fantasy">
                            <span></span>
                        </a>
                    </span>
                </div>
            </div>}
            <div class="iep">
                <div class="ieppic">
                    <a class="an" href={`/anime/${episode.anime_slug}`}>
                        <img class="coveri lazy" alt="Xin Jue" src={`${config.api.imageBase}/${episode.poster}`} itemprop="image" />
                    </a>
                </div>
                <div class="iepcon">
                    <div class="iepsbox">
                        {episode.episode_number && <a href={`/stream/${episode.slug}`}>
                            <div class="ieps">
                                <div class="ieps2 ">
                                    <div class="iepst1">
                                        <div class="centerv">Episode</div>
                                    </div><div class="iepst2">
                                        <div class="centerv">{episode.episode_number}</div>
                                    </div>
                                    <div class="iepst3">
                                        <div class="date-format-diff centerv">
                                            <Moment fromNow>{episode.date}</Moment>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>}
                    </div>
                    <div class="con">
                        <div class="condd">
                            <a class="cona" href={`/anime/${episode.anime_slug}`}>{episode.episode_name.split("Episode").shift().trim()}</a>
                            <h3 class="entry-title hidden" itemprop="headline">{episode.episode_name.split("Episode").shift().trim()}</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>

}

export default Episode;