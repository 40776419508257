import React, { useEffect, useState } from "react";

import Episode from "./Episode";
import ApiHandler from "../../ApiHandler.js";

function Favorites(props) {

    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        const loadMore = async () => {
            const token = window.localStorage.getItem("token");
            const res = await ApiHandler.get("/anime/user/bookmarks", {
                method: "POST",
                body: JSON.stringify({
                    token
                })
            });

            if (!res.error)
                setList(res.payload.data);

            setLoading(false)
        }

        loadMore();
    }, [setLoading]);

    return <>
        <h2>
            Bookmarks
        </h2>

        <div class="iepbox">
            {list.length ? <>
                {
                    list.map(episode => <Episode episode={episode} />)
                }

            </> : <>
                    {loading ? <h2>Loading</h2> : <h2>Nothing to display! You should bookmark some anime!</h2>}
                </>}
        </div>
    </>;

}

export default Favorites;