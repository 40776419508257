import React, { Component } from "react";

import moment from "moment";
import { Link } from "react-router-dom";
import DocumentMeta from "react-document-meta";
import "../../styles/schedule.scss";
import ApiHandler from "../../ApiHandler.js";
import EpisodeComponent from "../EpisodeComponent.js";
import PopularAnimeComponent from "../PopularAnimeComponent.js";
import AnimeComponent from "../AnimeComponent.js";
import Pagination from "../../lib/Pagination.js";
import config from "config";

import Sidebar from "../sidebar/Sidebar";


export default class Schedule extends Component {
	

	constructor(props) {
		super(props);

		this.state = {}

		this.setState({ latest: [] });
		this.setState({ latestPage: 0, OngoingAnimePage: 0, trendingAnimePage: 0 });

		this.today = moment().format("dddd");
		this.tomorrow = moment().add(1, "day").format("dddd");

		console.log(this.today)
	}

	componentDidMount() {

		ApiHandler

            .get(`/latest`)
            .then(d => {
              this.setState({ latest: d });
            });

            this.randomItems = {

			randomTV: "/anime/random/TV"

		};

		["randomTV"]
			.forEach(this.refreshRandom.bind(this));

		this.requestSchedule();

	}

	async requestSchedule() {

		const schedule = await ApiHandler
			.get("/anime/schedule");

		this.setState({
			schedule
		});

	}

	decideTitle(title) {

		return this.today === title ? "Today" : this.tomorrow === title ? "Tomorrow" : title
	}

	buildSchedule() {

		if (!this.state || !this.state.schedule)
			return (
				<div className="loading">
				<div class="spinner"><div></div><div class="rect2"></div><div class="rect3"></div><div class="rect4"></div><div class="rect5"></div></div>
				</div>
			);

		const { schedule } = this.state;

		const map = {
			"Today": [],
			"Tomorrow": [],
			"Monday": [],
			"Tuesday": [],
			"Wednesday": [],
			"Thursday": [],
			"Friday": [],
			"Saturday": [],
			"Sunday": []
		};


		for (const s of schedule) {

			const key = this.decideTitle(s.schedule);
			//console.log(key)
			if (!map[key])
				map[key] = [];

			map[key].push(s);

		}

		for (const key of Object.keys(map))
			if (!map[key].length) delete map[key];

		const { latest } = this.state;

		return (
			<div>
			<div className="maindark">
			<div class="iepbox">
			<div id="episodes-subbed">
			{ Object.keys(map).map(key => {
					return (

						<>
							<div class="lisb">{key}</div>

							{ map[key].map(v =>
								<a class="an" href={`/anime/${v.slug}`}>
  <div class="similarcmain">
    <div class="similarc">
      <div class="similarpic">
        <img class="coveri" src={`${config.api.imageBase}${v.image}`} itemprop="image" />
      </div>
      <div class="similard">
        <div class="similardd">{v.name}
        </div>
      </div>
    </div>
  </div>
</a>	) }
						</>
					)
				})
			}</div>
			</div>
		<div class="sidemain">
					<div className="noas">

			<Sidebar />

		</div>
			</div>

			</div>
			</div>
		)

	}

	render() {

		const meta = {

				title: `Anime Schedule - Watch Most Popular Anime Movies Online - ${config.website.name}`,
			    description: `Watch Anime Online English Subbed & English Dubbed for Free.`,
			    meta: {
			        charset: "utf-8",
			        name: {
			            keywords: ``
			        }
			    }
			
			}


		return (

			<div className="notmain psd">
			<DocumentMeta {...meta} />
				{this.buildSchedule()}
			</div>

		)

	}

	refreshRandom(key) {

		if (!this.state)

		this.setState({
			[key]: null
		});

		ApiHandler
			.get(this.randomItems[key])
			.then(d => this.setState({ [key]: d }));

	}

	buildRandom(key) {

		if (!this.state || !this.state[key]) return (
		<div className="loading">
		   Loading
		</div>
		);

		const anime = this.state[key];


		return (
		<>
		<div class="c">
    <a class="an" href={`/anime/${anime.slug}`}>
      <img class="coveri lazy" src={`${config.api.imageBase}${anime.image}`} alt={anime.name} />
    </a>
  </div>
  <div class="rnd">
  {anime.name}
  </div>
  </>
		)

	} 

	/*<EpisodeComponent episode={v} />*/

	handlePageSwap(key, indexa) {

		setTimeout(() => {
			this.setState({
				loading: {
					[key]: {
						active: false
					}
				}
			})
		}, 0)
		this.setState({
			[key]: indexa,
			loading: {
				[key]: {
					active: true,
					style: this[key].getBoundingClientRect()
				}
			}
		})

	}

	buildOngoing(items, current, key, component, title) {
		
		const pagination = new Pagination(items)
			.perPage(1000)
			.padding(1, 1)
			.gutter(1, 1)
			.get(current);

		return (
			<>
					{pagination.nav.currentPage.items.map((v, i) => 
						React.createElement(component, {
							payload: v,
							key: i + "key",
							index: v
						})
					)}
				</>
		)

	}

	buildPopular(items, current, key, component, title) {
		
		const pagination = new Pagination(items)
			.perPage(10)
			.padding(1, 1)
			.gutter(1, 1)
			.get(current);

		return (
			<>
					{pagination.nav.currentPage.items.map((v, i) => 
						React.createElement(component, {
							payload: v,
							key: i + "key",
							index: v
						})
					)}
				</>
		)

	}

}