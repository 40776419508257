import React from "react";
import { createBrowserHistory } from "history";
import NProgress from "nprogress";
import "../../src/styles/global.scss";

class Base extends React.Component {

	componentDidMount() {

		//NProgress.start();

	}

	render() {

		//NProgress.done();

		return (
			<div>
				{this.props.children}
			</div>

		)
	}

}


export default Base;