import React from 'react';
import ReactDOM from 'react-dom';

import Base from "./views/Base.js";
import Index from "./views/index/Index.js";
import Header from "./views/header/Header.js";
import Footer from "./views/footer/Footer.js";
import Anime from "./views/anime/Anime.js";
import Stream from "./views/stream/Stream.js";
import AnimeList from "./views/animelist/AnimeList.js";
import Browse from "./views/browse/Browse.js";
import Schedule from "./views/schedule/Schedule.js";
import Error404 from "./views/errors/404/404.js";
import Random from "./views/random/Random.js";
import Dmca from "./views/page/Dmca.js";
import RegistrationModel from "./lib/registrationModel/Base";
import Profile from "./views/profile/Profile";
import Account from "./lib/Account";


import { createBrowserHistory } from "history";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { RecoilRoot } from "recoil";

import * as serviceWorker from './serviceWorker';

const history = createBrowserHistory();

ReactDOM.render(
	<RecoilRoot>
		<Router history={history}>
			<Base>

				<Header history={history} />

				<Switch>
					<Route path="/" component={Index} exact />
					<Route path="/anime/:slug" component={Anime} />
					<Route path="/stream/:slug" component={Stream} />
					<Route path="/random" component={Random} exact />
					<Route path="/anime" component={AnimeList} />
					<Route path="/browse" component={Browse} />
					<Route path="/schedule" component={Schedule} />
					<Route path="/dmca" component={Dmca} />
					<Route path="/profile" component={Profile} />
					<Route component={Error404} />

				</Switch>
				<RegistrationModel />
				<Footer history={history} />
				<Account />
			</Base>
		</Router>
	</RecoilRoot>
	,
	document.getElementById('root')
);

serviceWorker.unregister();
