const pageAmount = Symbol("pageAmount");
const generateNav = Symbol("generateNav");


class Pagination {

	constructor(items) {

		this.data = {};

		this.data.items = items;
		this.data.perPage = 35;
		this.data.padding = 2;
		this.data.gutter = 2;

		return this;

	}

	perPage(i) { this.data.perPage = i; return this }

	padding(start, stop) { this.data.padding = { start, stop }; return this }

	gutter(start, stop) { this.data.gutter = { start, stop }; return this }

	get(current) {

		const { data } = this;

		const pages = new Array(this[pageAmount](data.items.length, data.perPage)).fill(0);

		for (const [i] of pages.entries()) {

			pages[i] = data.items.slice(i * data.perPage, i * data.perPage + data.perPage);

		}

		const nav = this[generateNav](pages, current);

		return { nav, self: this};

	}

	[pageAmount] (pages, perPage) {

		return Math.ceil(pages / perPage);

	}

	[generateNav] (pages, current = 0) {

		const isCurrent = (i, current) => i === current;

		const showConditions = [
			isCurrent,
			(i, current, total) => i < this.data.gutter.start || i > total - this.data.gutter.stop - 1,
			(i, current) => i >= current - this.data.padding.start && i <= current + this.data.padding.stop,
		];

		const buildIndex = (v, i, arr, forceShow = null) => {

			const show = forceShow !== null ? forceShow : showConditions.some(v => v(i, current, arr.length));

			const nextShow = arr[i + 1] && showConditions.some(v => v(i + 1, current, arr.length));

			const hasShow = nextShow &&
				arr
					.slice(0, i)
					.some((v, i) =>
						showConditions
							.some(v =>
								v(i, current, arr.length)
							)
						);

			return {

				name: i + 1 + "",
				
				first: i === 0,

				current: isCurrent(i, current),

				last: current === arr.length - 1,

				index: i,
				
				items: isCurrent(i, current) ? v : null,

				dotdotdot: !!(!show && nextShow && hasShow),

				show

			}

		}

		const nav = pages.map(buildIndex);


		const currentPage = buildIndex(pages[current], current, pages);

		const first = pages[0] && pages.length > 1 ? buildIndex(pages[0], 0, pages.length, !currentPage.first) : null;

		const next = pages[current + 1] && pages.length > 1 ? buildIndex(pages[current + 1], current + 1, pages.length) : null;

		const previous = pages[current - 1] && pages.length > 1 ? buildIndex(pages[current - 1], current - 1, pages.length) : null;

		const last = pages[pages.length - 1] && pages.length > 1 ? buildIndex(pages[pages.length - 1], pages.length - 1, pages.length, !currentPage.last) : null;

		

		return { current, nav, first, previous, currentPage, next, last }

	}

}

export default Pagination;