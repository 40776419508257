import React, { useEffect, useRef } from "react";

import "../../styles/popup.scss";

function Popup(props) {

    const popup = useRef(null);

    const closeEvent = e => {
        e.preventDefault();

        props.onClose();
    }

    useEffect(() => {

        const close = e => {
            

            if (document.activeElement === popup.current && (e.which || e.keyCode) === 27) {
                if (e.preventDefault) e.preventDefault();
                props.onClose();
            }
        }

        if (popup.current) popup.current.focus();

        window.addEventListener("keydown", close);

        return () => {
            window.removeEventListener("keydown", close);
        }

    }, [popup, props])

    return <>
        <div ref={popup} tabIndex="0" class={`popup ${props.shown ? "popup-shown" : ""}`}>
            <div class="popup-background" onClick={closeEvent}></div>
            <div class="popup-inner">
                <div className="close-inner">
                    <a href="#close" onClick={closeEvent}>
                        <div class="popup-close">
                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512" class="svg-inline--fa fa-times fa-w-11 fa-3x"><path fill="currentColor" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z" class=""></path></svg>
                        </div>
                    </a>
                </div>
                {props.children}
            </div>

        </div>
    </>;

}

export default Popup;