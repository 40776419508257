import React, { Component } from "react";

import "../../styles/details.scss"

import EpisodeList from "./EpisodeList.js";
import { Link } from "react-router-dom";

import DocumentMeta from "react-document-meta";

import Sidebar from "../sidebar/Sidebar";

import config from "config";

import moment from "moment";

import Bookmark from "../stream/Bookmark";
import ApiHandler from "../../ApiHandler";

class AnimeDetails extends Component {

	buildNiceAiredDate(date) {

		const format = date
			.split(" to ")
			.map(v => {
				return moment(v).format("MMM Do, YYYY");
			});

		return !format.length ? "N/A" : format.length === 1 ? format[0] : format.join(" to ");


	}

	serializeDistinctArray(arr) {

		return arr
			.filter(v => v)
			.map(v => {
				return {
					slug: v.trim().toLowerCase().replace(/ /gm, "-"),
					name: v.trim()
				}
			})

	}

	genList(arr, base) {

		return arr.map((v, i) => {
			return (
				<span>
					<span>{i !== 0 && ", "}</span>
					{base ? <Link class="inf-item" to={base && `${base}${this.serializeDistinctArray([v])[0].slug}`}>
						<span>{v}</span>
					</Link> : <span>{v}</span>}
				</span>)
		})

	}

	componentDidMount() {
		const token = window.localStorage.getItem("token");

		if (token) {
			const res = ApiHandler.get("/anime/user/progress/" + this.props.animeSlug, {
				method: "POST",
				body: JSON.stringify({
					token
				})
			}).then(d => {

				if (d.error) return;

				this.setState({
					progress: d.payload.data
				})

			});

		}

	}

	render() {


		const { anime } = this.props;

		/* if (!anime)
			return (
				<span>Loading...</span>
			) */

		if (anime && anime.genres instanceof Array)
			anime.genres = anime.genres.filter(v => v);

		if (anime && anime.synonyms instanceof Array)
			anime.synonyms = anime.synonyms.filter(v => v);

		return (
			<div>
				<div class="slider">
					<div class="slidermain">
						<div class="centerf">
							<div class="centerf2 centerfs">
								{anime &&
									<img
										class="sliderimg"
										src={`${anime.banner
											? `${config.api.imageBase}${anime.banner}`
											: "/assets/img/banner.webp"
											}`}
										alt={anime.name}
									/>
								}
							</div>
						</div>
					</div>
				</div>
				<div class="notmain">
					<div class="maindark">
						<div class="infoboxc">
							<div class="iepbox">
								<div class="infoboxc">
									<div class="infopicbox">
										{" "}
										{anime &&
											<>
												<img
													src={`${config.api.imageBase}${anime.image}`}
													alt={anime.name}
													class="posteri"
												/>
												
											</>
										}
									</div>
									<div class="infodesbox">
										<div class="infodes">
											<h1 class="entry-title" itemprop="name">
												{anime ? anime.name : "Loading..."}
											</h1>
										</div>
										<div class="infodes2">
											<div
												class="infodes2 entry-content entry-content-single"
												itemprop="description"
											>
												<p>{anime ? anime.synopsis : "Loading..."}</p>
											</div>
											<div class="textd">Alias:</div>
											{anime && <div class="textc">
												{anime.synonyms && anime.synonyms.length
													? this.genList(anime.synonyms)
													: "N/A"}
											</div>}
											<div class="textd">Genres/Tags:</div>
											{anime && <div class="textc">
												{anime.genres && anime.genres.length
													? this.genList(anime.genres, "/browse/?sort=a-z&genres=")
													: "N/A"}{" "}
											</div>}
											<div class="textd">Type:</div>
											<div class="textc">{anime ? anime.type : "Loading..."}</div>
											<div class="textd">Status:</div>
											<div class="textc">{anime ? anime.status : "Loading..."}</div>
											<div class="textd">Episodes:</div>
											<div class="textc">{anime ? anime.episodeCount : "Loading..."}</div>
											<div class="textd">Aired:</div>
											<div class="textc">
												{" "}
												{anime ? anime.aired && this.buildNiceAiredDate(anime.aired) : "Loading..."}
											</div>
										</div>
									</div>
								</div>
								<div class="infoepboxmain">
									<div class="infoepbox">
										{anime && <EpisodeList progress={this.state && this.state.progress} anime={anime} />}
									</div>
								</div>
							</div>
							<div class="sidemain">

							</div>
						</div>
						<Sidebar />
					</div>

				</div>
			</div>

		)


	}


}

export default AnimeDetails;
