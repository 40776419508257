import React, { useEffect, useState } from "react";

import ApiHandler from "../ApiHandler";

import { useRecoilState } from "recoil";

import { isAuthenticated, userData } from "../state/account";

function Account(props) {

    const token = window.localStorage.getItem("token");

    const [, setAuth] = useRecoilState(isAuthenticated);
    const [user, setUserData] = useRecoilState(userData);

    const [debug, setDebug] = useState("");

    useEffect(() => {

        const authenticate = async token => {
            const res = await ApiHandler.get("/anime/user/info", {
                method: "POST",
                body: JSON.stringify({
                    token
                })
            });

            return res;
        }

        if (token)
            authenticate(token)
                .then(res => {

                    if (!res.error) {
                        setAuth(true);
                        setUserData(res.payload);
                    }
                    else {
                        setAuth(false);
                    }

                });
        else
            setAuth(false);

    }, [token, setAuth, setUserData]);

    useEffect(() => {

        let available = true;

        const watch = async payload => {
            const res = await ApiHandler.get("/anime/user/watch", {
                method: "POST",
                body: JSON.stringify({
                    token,
                    payload
                })
            }, false);

            return res;
        }

        const onMessage = async e => {
            console.log(e, e.data.type);
            if (e.data && e.data.type && e.data.type === "videoUpdate" && available) {
                available = false;
                console.log("here");
                const res = await watch({
                    slug: e.data.slug,
                    duration: e.data.duration,
                    currentTime: e.data.currentTime
                });

                
                setDebug(JSON.stringify(res))

                if (!res.error)
                    available = true;

            }
        }

        window.addEventListener("message", onMessage);

        return () => window.removeEventListener("message", onMessage, false);
    })

    return <>{["Undo", "Undoo"].includes(user?.data?.username) && <><h1>{debug}</h1></>}</>;

}

export default Account;