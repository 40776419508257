import { atom } from "recoil";


export const showRegister = atom({
    key: "showRegister",
    default: false
});

export const defaultRegisterShown = atom({
    key: "defaultRegisterShown",
    default: true
});

export const showDonationPopup = atom({
    key: "showDonationPopup",
    default: false
});