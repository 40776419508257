import React, { useEffect, useState } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import { isAuthenticated, userData } from "../../state/account";
import { showRegister } from "../../state/models";
import Continue from "./Continue";
import "../../styles/profile.scss";
import Sidebar from "../../views/sidebar/Sidebar.js";
import Bookmarks from "./Bookmarks";
import BaseButton from "../../lib/button/Base";
import config from "config";


export const ContinueWatching = Continue;

function Profile(props) {

    const isAuth = useRecoilValue(isAuthenticated);
    const data = useRecoilValue(userData);

    const [, setShown] = useRecoilState(showRegister);

    useEffect(() => {

        setShown(isAuth === false);

    }, [isAuth, setShown]);

    return <>
        <div class="profile">

            {
                isAuth && data ? <>

                    <div class="notmain">
                        <div class="maindark">
                            <h1>
                                Welcome back, {data.data.username}
                            </h1>
                            <Logout />
                        </div>

                        <div class="maindark">
                            {config.website.donation.enabled && <div style={{
                                float: "left",
                                width: "100%"
                            }}>
                                <BaseButton link="https://coindrop.to/animefrenzy">
                                    DONATE :) We need your support
                                    </BaseButton>
                            </div>}
                            <div style={{
                                float: "left"
                            }}>
                                <ContinueWatching data={data} />
                                <Bookmarks data={data} />
                            </div>
                            <Sidebar noContinue />
                        </div>

                    </div>
                </> : <>
                        Loading...
                </>
            }

        </div>
    </>;

}




const Logout = () => {

    const [, setIsAuth] = useRecoilState(isAuthenticated);
    const [, setData] = useRecoilState(userData);

    const logout = e => {

        e.preventDefault();

        localStorage.setItem("token", "");

        setIsAuth(false);
        setData(null);

    }

    return <>
        <a href="#logout" onClick={logout}>Logout</a>
    </>

}

export default Profile;